import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = [ "form" ];

  submit(event) {
    event.preventDefault();

    let formData = new FormData(this.formTarget);

    let { utm_source = "", utm_medium = "", utm_campaign = "", utm_content = "", utm_term = "" } = Cookies.get();

    Cookies.set('utm_source', utm_source, { expires: 1 });
    Cookies.set('utm_medium', utm_medium, { expires: 1 });
    Cookies.set('utm_campaign', utm_campaign, { expires: 1 });
    Cookies.set('utm_content', utm_content, { expires: 1 });
    Cookies.set('utm_term', utm_term, { expires: 1 });

    const recaptchaResponse = grecaptcha.getResponse();
    if (!recaptchaResponse) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    formData.append('g-recaptcha-response', recaptchaResponse);
    formData.append('utm_source', utm_source);
    formData.append('utm_medium', utm_medium);
    formData.append('utm_campaign', utm_campaign);
    formData.append('utm_content', utm_content);
    formData.append('utm_term', utm_term);
    formData.append('source_url', window.location.href);

    this.sendRequest(formData);
  }

  sendRequest(formData) {
    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData
    })
      .then(response => this.handleResponse(response))
      .then(data => this.handleSuccess(data))
      .catch(error => this.handleError(error));
  }

  handleResponse(response) {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  handleSuccess(data) {
    if (data.success) {
      this.redirectToThankYouPage(data.redirect_to);

      if (location.hostname !== 'localhost') {
        this.trackSubmission();
      }

      this.showSuccessMessage(data.message);
    } else {
      console.error('Error:', data.message);
    }
  }

  handleError(error) {
    console.error('Error:', error);
  }

  redirectToThankYouPage(url) {
    window.location.href = url;
  }

  trackSubmission() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'SubmitFormContacts'});
    gtag('js', new Date());
  }

  showSuccessMessage(message) {
    let element = document.getElementById("message_partial");
    element.classList.add('sending-alert');
    element.innerHTML = message;

    setTimeout(() => {
      this.formTarget.reset();
    }, 1000);

    setTimeout(() => {
      element.classList.remove('sending-alert');
      element.innerHTML = '';
    }, 5000);
  }
}
